import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import Img from "gatsby-image";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/compliance/banner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import { Container } from "reactstrap";
import SvgIconsSet from "../../../components/SvgIconsSet";

//Accordian
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import plus from "../../../images/icons/plus.svg";
import minus from "../../../images/icons/minus.svg";

//Images
import BannerDevops from "../../../images/services/compliance/banner-devops.jpg";
import BannerIdentity from "../../../images/services/compliance/banner-identity.jpg";
import BannerApi from "../../../images/services/compliance/banner-api.png";
import BannerQa from "../../../images/services/compliance/banner-qa.jpg";
import ConsultationBanner from "../../../images/consultation.jpg";
import productinnovation from "../../../images/product-innovation.jpg";

//Our Process
import OurProcess from "../../../components/services/OurProcess";

//Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";

const ComplianceServices = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        keyword="Business Compliance Services"
        title="Compliance Services"
        description="Manage your compliance obligations with confidence. We work with you to improve site reliabilty, manage identify verification, ensure API governance and delivery quality assurance services. Contact us to find out more."
      />
      {/* <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Compliance Services</h1>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <IntroBanner
        title="Compliance Services"
        subtitle="Manage compliance obligations with confidence"
        image={Banner}
      />
      {/* <ContrastSection
        title="Manage compliance obligations with confidence"
        para={["Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar."]}
      /> */}
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerDevops} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">DevOps &amp; Site Reliability</h2>
            <p>
              <strong>Fast & efficient system reliability.</strong>
              <br/>
              Achieve high system reliability, facilitate faster deployment and ensure continuous system performance and uptime with integrated software development and IT operations.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerIdentity} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Identity Management</h2>
            <p>
              <strong>Enhance data security & regulatory compliance.</strong>
              <br/>
              Manage user identities, authentication and access control with ease. We use Role-based access control, principle of least privilege; multi-factor authentication and single sign-on.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerApi} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">API Governance</h2>
            <p>
              <strong>Ensure regulatory and organisation compliance.</strong>
              <br/>
              We can take care of the creation, deployment, and maintenance of APIs, ensuring they meet all requirements, legislation and business objectives. We leverage a number of tools in our governance services including WS02 API manager integration, access control and authentication processes.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerQa} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Quality Assurance &amp; Compliance</h2>
            <p>
              <strong>Mitigate risk and ensure adherence to industry standards & regulations.</strong>
              <br/>
              Enhance reliability, improve operational efficiency and ensure supplier quality. We monitor planned and systematic activities to fulfil quality requirements for a system, product, program, or service including assured compliance.
            </p>
          </div>
        </div>
        <div className="getting-touch"><GetInTouchbtn textdce={'Free consultation'} /></div>
      </div>
      <Container>
        <OurProcess />
      </Container>
      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "cloud-services-1024x597.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default ComplianceServices;
